.MediaPanelWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
}

.LocalVideoWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /*flex-basis: 45%;*/
    align-self: center;
    width: 100%;
    height: 15vh;
    min-height: 150px;
}

.IsProcessingWrapper {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15vh;
}

.IsProcessingGraphic {
    position: absolute;
    display: flex;
    justify-content: center;
    align-self: center;
    justify-self: center;
    align-items: center;
    background-color: #00000088;
    z-index: 10;
    width: 10vh;
    height: 10vh;
    animation-name: pulsateProcessingBorder;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    border-radius: 100% 100%;
}

.IsProcessingGraphicInner {
    animation-name: pulsateProcessingColor;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    font-size: 2.2vh;
    text-align: center;
}

@keyframes pulsateProcessingBorder {
    0% {
        border: 0.25vh solid rgb(var(--Primary-Color));
    }
    50% {
        border: 0.25vh solid rgb(var(--Secondary-Color));
    }
    100% {
        border: 0.25vh solid rgb(var(--Primary-Color));
    }
}

@keyframes pulsateProcessingColor {
    0% {
        color: rgb(var(--Primary-Color));
    }
    50% {
        color: rgb(var(--Secondary-Color));
    }
    100% {
        color: rgb(var(--Primary-Color));
    }
}
.LocalVideo {
    position: absolute;
    object-fit: contain;
    width: auto;
    z-index: 1;
    aspect-ratio: 16 / 9;
    max-width: 100%;
    height: 92%;
    padding: 0.1vmin;
    background-color: black;
    border-radius: 0.3vmin;
    border: 1px solid rgba(var(--Border-Color), var(--Border-Alpha));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.05vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
}

.LocalVideoWrapper .VideoFraming {
    position: absolute;
    object-fit: contain;
    width: auto;
    z-index: 2;
    aspect-ratio: 16 / 9;
    max-width: 100%;
    height: 92%;
    padding: 0.1vmin;
	filter: hue-rotate(220deg) brightness(250%);
}

.SettingsMenuPresentationWrapper {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 5;
}

.FillElement {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 40%;
    background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
    box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
}

.SettingsMenuPresentationWrapperOuter {
    align-self: center;
    flex-grow: 1;
    padding: 0.5vmin;
    padding-bottom: 1.0vmin;
    width: 100%;
    border-radius: 0.2vmin;
}

.SettingsMenuPresentationWrapperInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    flex-basis: 20vh;
}

.SettingsMenuPresentationWrapperInner > select {
    flex-grow: 1;
    width: 100%;
    margin: 1%;
	border: none;
    outline: none;
    font-weight: 500;
    padding: calc(5px + 0.8vmin);
}

.SettingsMenuButtonWrapper {
    display: flex;
    flex-basis: 15%;
    width: 100%;
    padding: 1.5vmin;
    justify-content: space-evenly;
}

.AudioConfigWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-self: center;
    color: white;
    font-weight: 300;
}

.AudioConfigWrapper > div:last-child {
    font-size: 1.3vmin;
    text-align: center;
}

.AudioConfigWrapper > .TransparentButton {
    align-self: center;
    width: 2vmin;
    max-width: 2vmin;
    height: 2vmin;
    margin: 0;
    padding: 0;
    flex: none;
    border-radius: 100%;
    filter: brightness(180%);
}

.AutoAnswerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-basis: 18%;
    width: 100%;
    flex-shrink: 0;
    align-self: center;
    color: white;
    font-weight: 300;
    padding-bottom: 2%;
}

.AutoAnswerWrapper > .TransparentButton {
    height: 2vmin;
    flex-basis: 2vmin;
    flex-shrink: 1;
    flex-grow: 0;
    border-radius: 60%;
    filter: brightness(180%);
    margin-right: 3%;
}

.AutoAnswerWrapper > .TransparentButton.active {
    filter: brightness(180%) !important;
}

.AutoAnswerWrapper > * {
    align-self: center;
    font-weight: 300;
}

.MediaRefreshButton {
    flex-grow: 0 !important;
    flex-shrink: 0;
    flex-basis: 50% !important;
    height: 90%;
}

.MediaRefreshButtonWarning 
{
    animation-name: pulsateRefreshBackground;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
}

@keyframes pulsateRefreshBackground {
    0% {
        background: rgb(var(--Primary-Color-Light)) !important;
        color: var(--Primary-Color-Text);
    }
    50% {
        background: rgb(var(--Error-Color-Light)) !important;
        color: var(--Error-Color-Text);
    }
    100% {
        background: rgb(var(--Primary-Color-Light)) !important;
        color: var(--Primary-Color-Text);
    }
}

.MediaSettingsLabel {
    font-size: 2.5vmin !important;
    font-weight: 400;
    color: rgb(var(--Surface-Color-Text));
}

.ToggleSurfaceWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    padding: 1.25vmin;
    border-radius: 5px;
    background: linear-gradient(to bottom, rgb(var(--Surface-Color-Light)), rgb(var(--Surface-Color-Very-Light)));
    border: 1px solid rgba(var(--Border-Color), var(--Border-Alpha));
    box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(2px + 0.05vmin) rgba(var(--Shadow-Color), var(--Shadow-Alpha));
}

.ToggleSurfaceWrapper > div {
    flex-direction: row;
    flex-wrap: nowrap;
    width: 50%;
    flex-shrink: 0;
    padding: calc(2px + 0.25vmin);
}

.HeadingWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    color: rgb(var(--Surface-Color-Text));
}